<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="getGeneratedPayout">

            <div class="col-md-6">
              <div class="form-group">
                <label>Select week</label>
                <input type="week" class="form-control" v-model="selectedWeek">
              </div>
              <button class="btn btn-success btn-sm" type="submit">Submit</button>
            </div>
          </form>

        </div>
      </div>
      <div class="card" v-if="payoutLoader">
        <div class="card-body">
          <div class="text-center">
            <div class="row">
              <div class="col-md-3">
                <h3 class="lines shine"></h3>
                <p class="text-uppercase mb-1 font-13 font-weight-medium">
                  Total Drivers
                </p>
              </div>
              <div class="col-md-3">
                <p class="lines shine"></p>
                <p class="text-uppercase mb-1 font-13 font-weight-medium">
                  Total Cost
                </p>
              </div>
              <div class="col-md-3">
                <h3 class="lines shine"></h3>
                <p class="text-uppercase mb-1 font-13 font-weight-medium">
                  Total Income
                </p>
              </div>

              <div class="col-md-3">
                <h3 class="lines shine"></h3>
                <p class="text-uppercase mb-1 font-13 font-weight-medium">
                  Transfer to bank
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="payout">
          <div v-if="payout['payoutSummary']">
            <div class="card">
              <div class="card-body">
                <div class="text-center">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="py-1">
                        <h3 class="text-info"> {{ payout['payoutSummary']['total_number_drivers_for_cost'] }} </h3>
                        <p class="text-uppercase mb-1 font-13 font-weight-medium">
                          Total Drivers
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="py-1" @click="showCostDetails()" style="cursor:pointer">
                        <h3 class="text-warning"> {{ payout['payoutSummary']['total_cost'] | toCurrencyFormat }} </h3>
                        <p class="text-uppercase mb-1 font-13 font-weight-medium">
                          Total Cost
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="py-1" @click="showIncomeDetails()" style="cursor:pointer">
                        <h3 class="text-success"> {{
                            payout['payoutSummary']['total_income']  | toCurrencyFormat
                          }} </h3>
                        <p class="text-uppercase mb-1 font-13 font-weight-medium">
                          Total Income
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="py-1" @click="showTransferToBankDetails()" style="cursor:pointer">
                        <h3 class="text-danger"> {{ payout['actualTotalToBank']  | toCurrencyFormat }} </h3>
                        <p class="text-uppercase mb-1 font-13 font-weight-medium">
                          Total Trans. To Bank ({{ payout['actualTotalToBankDetails'].length }})
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card" v-else>
            <div class="card-body">
              <h4 class="card-title text-danger">{{ this.payoutSummaryText }}</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <Sidepanel @onDriverSelected="onDriverSelected" :driverSelect="true" @removeDriverSelection="removeDriverDetails()"/>
          <div class="inbox-rightbar">
            <!--              <Toolbar />-->
            <!--           /////////////////////// CONTENT HERE -->
            <div v-if="driver">
              <div class="mt-0">
                <h5 class="font-18">
                  <span class="mr-3">{{ driver['name'] }} {{ driver['other_names'] }}</span>
                  <template v-if="driver['prospective_driver'] && driver['prospective_driver']['service_type']">
                    <span
                        class="badge badge-dark p-1"> {{
                        driver['prospective_driver']['service_type'] | serviceType
                      }}</span>
                  </template>
                  <template v-else>
                    (No service selected)
                  </template>

                  <span class="ml-2">|</span>
                  <span class="ml-3">Accumulated Arrears = </span>
                  <span v-if="!accumulatedArrearsLoader">{{ accumulatedArrears | toCurrencyFormat }}</span>
                  <span v-else class="lines shine col-1"></span>

                </h5>
              </div>
              <hr>

              <!-- content here -->
              <div v-if="driverPayoutLoader">
                <b-spinner small></b-spinner>
                loading...
              </div>
              <div v-else>
                <template v-if="driverPayoutDetails && driverPayoutDetails['payoutDetail']">
                  <h5 class="card-title"> Summary </h5>
                  <table class="table table-striped">
                    <tr>
                      <td>New accumulated arrears (when payout is confirmed)</td>
                      <td>{{ newAccumulatedArrears | toCurrencyFormat }}</td>
                    </tr>
                  </table>
                  <hr>
                  <b-card>
                    <h5 class="card-title"> Cost breakdown </h5>
                    <table class="table table-striped">
                      <tr>
                        <td>Total Cost</td>
                        <td>{{ driverPayoutDetails['payoutDetail']['driver_total_cost'] | toCurrencyFormat }}</td>
                      </tr>
                    </table>
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th>Field name</th>
                        <th>Name displayed to driver</th>
                        <th>Field value</th>
                      </tr>
                      </thead>
                      <template v-if="driverPayoutDetails['costBreakDown'].length > 0" v-for="cb in driverPayoutDetails['costBreakDown']">
                        <tr>
                          <td>{{ cb['fieldName'] }}</td>
                          <td>{{ cb['fieldNameDisplay'] }}</td>
                          <td>{{ cb['fieldValue'] }}</td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
                          <td colspan="3">
                            No costs available
                          </td>
                        </tr>
                      </template>

                    </table>
                  </b-card>
                  <hr>
                  <b-card>
                    <h5 class="card-title"> Income breakdown </h5>
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th>Provider</th>
                        <th>Total field name</th>
                        <th>Total value</th>
                        <th>Calculated field value</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template
                          v-for="payload in (driverPayoutDetails['payoutDetail']['income_payload'] ? JSON.parse(driverPayoutDetails['payoutDetail']['income_payload']) : [] )">
                        <tr>
                          <td>{{ payload['name'] }}</td>
                          <td>{{ payload['totalFieldName'] }}</td>
                          <template v-if="payload['hasTotalField']">
                            <td><span class="text-success">{{ payload['totalFieldValue'] }}</span></td>
                          </template>
                          <template v-else>
                            <td>N/A</td>
                          </template>
                          <td>
                            <template v-if="payload['hasTotalField']">
                              <span>{{ payload['calculatedIncome'] }}</span>
                            </template>
                            <template v-else>
                              <span class="text-success">{{ payload['calculatedIncome'] }}</span>
                            </template>

                          </td>

                        </tr>
                      </template>
                      </tbody>
                      <!--                  [{"providerId":"e232985f-2d39-45a0-bc89-c545f9cab413","name":"UBER","hasTotalField":true,"useProviderTotalField":true,"totalFieldName":"Total","totalFieldValue":"918.45","calculatedIncome":-60.7457}]-->

                    </table>
                  </b-card>
                  <b-card>
                    <h5 class="card-title"> Balance for {{ this.selectedWeek }} </h5>
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th>Total income for {{ this.selectedWeek }}</th>
                        <th>Total cost for {{ this.selectedWeek }}</th>
                        <th>Balance for {{ this.selectedWeek }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>{{ selectedWeekTotalIncome | toCurrencyFormat }}</td>
                        <td>{{ selectedWeekTotalCost | toCurrencyFormat }}</td>
                        <td>{{ selectedWeekBalance | toCurrencyFormat }}</td>
                      </tr>
                      </tbody>
                      <!--                  [{"providerId":"e232985f-2d39-45a0-bc89-c545f9cab413","name":"UBER","hasTotalField":true,"useProviderTotalField":true,"totalFieldName":"Total","totalFieldValue":"918.45","calculatedIncome":-60.7457}]-->
                    </table>
                    <h5 class="card-title"> Money to be transferred to bank on {{ this.selectedWeek }} </h5>
                    <table class="table">
                      <tr>
                        <td>To be transferred to bank</td>
                        <template v-if="transferToBank > 0">
                          <td class="text-success">{{ transferToBank | toCurrencyFormat }}</td>
                        </template>
                        <template v-else>
                          <td class="text-danger">{{ transferToBank | toCurrencyFormat }}</td>
                        </template>

                      </tr>
                    </table>
                  </b-card>
                </template>
                <h4 v-else class="card-title text-danger text-center mt-5">{{ driverPayoutSummaryText }}</h4>

              </div>
            </div>
            <div class="text-center mt-5" v-else>
              <h4>Select a driver to view details...</h4>
            </div>
            <!-- END OF CONTENT HERE -->
          </div>

        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->

      <div class="row" v-if="payout && payout['payoutSummary']">
        <div class="col-md-12">
          <b-card class="text-center">
            <b-form-checkbox
                id="checkbox-1"
                v-model="usePush"
                name="checkbox-1"
                value="yes"
                unchecked-value="no"
                class="pb-4"
            >
              Send out push notifications
            </b-form-checkbox>
            <button v-if="payout['payoutSummary']['status'] === 'pending'" class="btn btn-dark mr-2"
                    @click="deleteGeneratedPayout"><i class="fas fa-ban"></i> Delete generated payout
            </button>
            <button class="btn btn-success mr-2" @click="confirmPayoutForWeek(payout['payoutSummary']['id'])"><i
                class="fas fa-check-circle"></i> Confirm payout for {{ selectedWeek }}
            </button>
          </b-card>
        </div>
      </div>
    </div>
    <b-modal
        id="modal-1"
        size="lg"
        v-model="show_detail_modal"
        :title="title"
        header-close-variant="light"
        title-class="font-18"
        hide-footer
    >
      <div class="text-right mb-2">
        <button class="btn btn-primary btn-sm" :disabled="downloadLoader" @click="downloadData('transfer_to_bank')">
          {{ downloadLoader ? 'Downloading...' : 'Download' }} <i class="fa fa-download"/></button>
      </div>

      <b-table
          responsive
          sticky-header
          table-class="table table-centered w-100"
          thead-tr-class="bg-light"
          :items="transferToBankData"
          :fields="fields"
          ref="selectableTable"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          show-empty
          selectable
          @row-selected="onRowSelected"
      >
        <template v-slot:cell(checkbox)="row">
          <b-form-checkbox @click.stop v-model="row['rowSelected']"
                           @change="onCheckBoxChanged(row)"></b-form-checkbox>
        </template>
        <template v-slot:cell(driver.name)="data">
          <a target="_blank" v-if="data.item.driver"
             :href="'/prospective-driver-detail/' + data.item.driver.id">{{ data.item.driver.name }}</a>
          <div v-else>N/A</div>
        </template>

        <template v-slot:cell(transferToBank)="data">
          {{ data.item.transferToBank | toCurrencyFormat }}
        </template>

        <template v-slot:cell(accumulated_arrears)="data">
          {{ data.item.accumulated_arrears | toCurrencyFormat }}
        </template>

        <template v-slot:cell(totalIncomeForWeek)="data">
          {{ data.item.totalIncomeForWeek | toCurrencyFormat }}
        </template>

        <template v-slot:cell(totalCostForWeek)="data">
          {{ data.item.totalCostForWeek | toCurrencyFormat }}
        </template>

        <template v-slot:cell(totalBalanceForWeek)="data">
          {{ data.item.totalBalanceForWeek | toCurrencyFormat }}
        </template>


      </b-table>

    </b-modal>
    <b-modal
        id="modal-1"
        size="lg"
        v-model="incomeModal"
        :title="title"
        header-close-variant="light"
        title-class="font-18"
        hide-footer
    >
      <div class="text-right mb-2">
        <button class="btn btn-primary btn-sm" :disabled="downloadLoader" @click="downloadData('income')">
          {{ downloadLoader ? 'Downloading...' : 'Download' }} <i class="fa fa-download"/></button>
      </div>
      <b-table
          responsive
          sticky-header
          table-class="table table-centered w-100"
          thead-tr-class="bg-light"
          :items="incomeDetails"
          :fields="incomeFields"
          ref="selectableTable"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          show-empty
          selectable
          @row-selected="onRowSelected"
      >
        <template v-slot:cell(checkbox)="row">
          <b-form-checkbox @click.stop v-model="row['rowSelected']"
                           @change="onCheckBoxChanged(row)"></b-form-checkbox>
        </template>
        <template v-slot:cell(driver_user.name)="data">
          <a target="_blank" v-if="data.item.driver_user"
             :href="'/prospective-driver-detail/' + data.item.driver_user.id">{{ data.item.driver_user.name }}</a>
          <div v-else>N/A</div>
        </template>

        <template v-slot:cell(driver_total_income)="data">
          {{ data.item.driver_total_income | toCurrencyFormat }}
        </template>

        <template v-slot:cell(status)="data">
          {{ data.item.status | toCurrencyFormat }}
        </template>

      </b-table>

    </b-modal>
    <b-modal
        id="modal-1"
        size="lg"
        v-model="costModal"
        :title="title"
        header-close-variant="light"
        title-class="font-18"
        hide-footer
    >
      <div class="text-right mb-2">
        <button class="btn btn-primary btn-sm" :disabled="downloadLoader" @click="downloadData('cost')">
          {{ downloadLoader ? 'Downloading...' : 'Download' }} <i class="fa fa-download"/></button>
      </div>
      <b-table
          responsive
          sticky-header
          table-class="table table-centered w-100"
          thead-tr-class="bg-light"
          :items="costDetails"
          :fields="costFields"
          ref="selectableTable"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          show-empty
          selectable
          @row-selected="onRowSelected"
      >
        <template v-slot:cell(checkbox)="row">
          <b-form-checkbox @click.stop v-model="row['rowSelected']"
                           @change="onCheckBoxChanged(row)"></b-form-checkbox>
        </template>
        <template v-slot:cell(driver_user.name)="data">
          <a target="_blank" v-if="data.item.driver_user"
             :href="'/prospective-driver-detail/' + data.item.driver_user.id">{{ data.item.driver_user.name }}</a>
          <div v-else>N/A</div>
        </template>

        <template v-slot:cell(driver_total_income)="data">
          {{ data.item.driver_total_cost | toCurrencyFormat }}
        </template>

        <template v-slot:cell(status)="data">
          {{ data.item.status | toCurrencyFormat }}
        </template>

      </b-table>

    </b-modal>
  </div>
</template>

<script>
import {payoutService} from "../../../../apis/payout.service";
import Sidepanel from "./sidepanel";
import {Skeleton} from "vue-loading-skeleton";
import {financialAdministrationService} from "../../../../apis/financial.administration.service";
import moment from "moment";
import {confirm} from "../../../../utils/functions";

export default {
  name: "make-payout",
  components: {
    Sidepanel,
    Skeleton
  },
  data() {
    return {
      driver: null,
      selectedWeek: '',
      title: 'Full details of financial record',
      show_detail_modal: false,
      data: null,
      selectedIncomeCostType: '',
      accumulatedArrears: '0.00',
      payout: null,
      driverPayoutDetails: null,
      downloadLoader: false,
      usePush: 'no',
      transferToBank: 0.00,
      newAccumulatedArrears: 0.00,
      selectedWeekTotalIncome: '0.00',
      selectedWeekTotalCost: '0.00',
      selectedWeekBalance: '0.00',
      transferToBankData: [],
      fields: [
        {
          label: "Driver name",
          key: "driver.name",
          sortable: false,
        },
        {
          label: "Dossier",
          key: "driver.driver.dossier",
          sortable: false,
        },
        {
          label: "Transfer To Bank",
          key: "transferToBank",
          sortable: false,
        },
        {
          label: "Accumulated Arrears",
          key: "accumulated_arrears",
          sortable: false,
        },
        {
          label: "Total Income For Week",
          key: "totalIncomeForWeek",
          sortable: false,
        },
        {
          label: "Total Cost For Week",
          key: "totalCostForWeek",
          sortable: false,
        },
        {
          label: "Total Balance For Week",
          key: "totalBalanceForWeek",
          sortable: false,
        },
      ],
      incomeFields: [
        {
          label: "Driver name",
          key: "driver_user.name",
          sortable: true,
        },
        {
          label: "Dossier",
          key: "driver_user.driver.dossier",
          sortable: false,
        },
        {
          label: "Total Income",
          key: "driver_total_income",
          sortable: true,
        },
        {
          label: "status",
          key: "status",
          sortable: false,
        }
      ],
      costFields: [
        {
          label: "Driver name",
          key: "driver_user.name",
          sortable: true,
        },
        {
          label: "Dossier",
          key: "driver_user.driver.dossier",
          sortable: false,
        },
        {
          label: "Total Cost",
          key: "driver_total_cost",
          sortable: true,
        },
        {
          label: "status",
          key: "status",
          sortable: false,
        }
      ],
      sortBy: "driver.name",
      sortDesc: true,
      filter: null,
      filterOn: [],
      selectedItems: [],
      incomeDetails: [],
      incomeModal: false,
      costDetails: [],
      costModal: false,
      driverPayoutLoader: false,
      payoutLoader: false,
      accumulatedArrearsLoader: false,
      payoutSummaryText: '',
      driverPayoutSummaryText: '',
    }
  },
  computed: {
    totalCost() {
      if (!this.payout) {
        return 0.00;
      }
      return this.payout['driversPayoutDetails'].reduce((a, b) => a + (Number(b['driver_total_income']) || 0), 0)
    },
  },
  methods: {
    downloadData(type) {
      let payload = this.getPayload(type);
      this.downloadDataBackend(payload);
    },

    downloadDataBackend(payload) {
      this.downloadLoader = true;
      payoutService.downloadData(payload).then((data) => {
        this.downloadLoader = false;

        if (!data.status) {
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }
        location.href = `${process.env.VUE_APP_API_BASE_URL}/get-download-data?file=${data['extra']}`;
      })
    },


    getPayload(type) {
      let header = [];
      let data = [];
      let file_name = '';

      if (type === 'cost') {
        file_name = `cost_details_for_${this.selectedWeek}.xlsx`;
        header = this.costFields.map((field) => field['label']);
        data = this.costDetails.map((detail) => {
          return {
            name: detail['driver_user']['name'],
            dossier: detail['driver_user']['driver']['dossier'],
            total_cost: detail['driver_total_cost'],
            status: detail['status'],
          }
        });
      }

      if (type === 'income') {
        file_name = `income_details_for_${this.selectedWeek}.xlsx`;
        header = this.incomeFields.map((field) => field['label']);
        data = this.incomeDetails.map((detail) => {
          return {
            name: detail['driver_user']['name'],
            dossier: detail['driver_user']['driver']['dossier'],
            total_cost: detail['driver_total_income'],
            status: detail['status'],
          }
        });
      }

      if (type === 'transfer_to_bank') {
        file_name = `transfer_to_bank_details_for_${this.selectedWeek}.xlsx`;
        header = this.fields.map((field) => field['label']);
        data = this.transferToBankData.map((detail) => {
          return {
            name: detail['driver']['name'],
            dossier: detail['driver']['driver']['dossier'],
            transferToBank: detail['transferToBank'],
            accumulated_arrears: detail['accumulated_arrears'],
            totalIncomeForWeek: detail['totalIncomeForWeek'],
            totalCostForWeek: detail['totalCostForWeek'],
            totalBalanceForWeek: detail['totalBalanceForWeek']
          }
        });
      }
      return {
        file_name,
        header,
        data
      };
    },

    showTransferToBankDetails() {
      this.transferToBankData = this.payout['actualTotalToBankDetails'];
      this.show_detail_modal = true;
      this.title = 'Transfer to bank details for ' + this.selectedWeek;
    },

    showIncomeDetails() {
      this.incomeDetails = this.payout['driversPayoutDetails'];
      this.incomeModal = true;
      let totalCost = this.payout['driversPayoutDetails'].reduce((a, b) => a + (Number(b['driver_total_income']) || 0), 0);
      totalCost = totalCost ? process.env.VUE_APP_CURRENCY + " " + (Math.round(totalCost * 100) / 100).toFixed(2) : totalCost;
      this.title = 'Income details for ' + this.selectedWeek + ' (' + totalCost + ' )';
    },
    showCostDetails() {
      this.costDetails = this.payout['driversPayoutDetails'];
      this.costModal = true;
      let totalCost = this.payout['driversPayoutDetails'].reduce((a, b) => a + (Number(b['driver_total_cost']) || 0), 0);
      totalCost = totalCost ? process.env.VUE_APP_CURRENCY + " " + (Math.round(totalCost * 100) / 100).toFixed(2) : totalCost;
      this.title = 'Cost details for ' + this.selectedWeek + ' (' + totalCost + ' )';
    },

    onRowSelected(selectedRows) {
      this.selectedItems = selectedRows;
    },

    onCheckBoxChanged(row) {
      const index = row['index']
      if (this.$refs.selectableTable.isRowSelected(index)) {
        this.$refs.selectableTable.unselectRow(index)
      } else {
        this.$refs.selectableTable.selectRow(index)
      }
    },

    onDriverSelected(driver) {
      this.driver = driver;
    },

    onFilterChanged() {
      this.fetchFinancialData();
    },

    getPreviousWeekInHtmlFormat() {
      const year = moment().year();
      const week = moment().week();
      return year + '-W' + week.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      // console.log('weekInHtmlFormat',weekInHtmlFormat)
    },

    confirmPayoutForWeek(payoutId) {

      confirm('Confirm payout for ' + this.selectedWeek, () => {
        const payload = {
          'use_push': this.usePush,
          'payout_id': payoutId
        }

        this.$store.dispatch('showLoader')
        payoutService.confirmGeneratePayout(payload).then((data) => {
          this.$store.dispatch('hideLoader')

          if (!data.status) {
            this.$store.dispatch('error', {message: data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message: data.message, showSwal: true})
          this.getGeneratedPayout();

        })
      })

    },


    getArrearsForDriver() {
      if (!this.driver) {
        return;
      }

      this.accumulatedArrearsLoader = true;
      payoutService.fetchArrears(this.driver['id']).then((data) => {
        this.accumulatedArrearsLoader = false;

        if (!data.status) {
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }

        this.accumulatedArrears = data['extra']['accumulated_amount'];

      })

    },

    getGeneratedPayout() {
      const payload = {
        'week': this.selectedWeek,
        'status': 'pending'
      }

      this.payoutLoader = true;
      payoutService.getGeneratePayout(payload).then((data) => {
        this.payoutLoader = false;

        if (!data.status) {
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }

        this.payout = data['extra'];
        this.payoutSummaryText = data['extra']['summaryText']
      })

      this.getDriverPayoutDetails();

    },

    getDriverPayoutDetails() {
      const payload = {
        'week': this.selectedWeek,
        'driver_user_id': this.driver['id'],
        'status': 'pending'
      }

      this.driverPayoutLoader = true;
      payoutService.getDriverPayoutDetails(payload).then((data) => {
        this.driverPayoutLoader = false;

        if (!data.status) {
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }

        this.driverPayoutDetails = data['extra'];
        if (this.driverPayoutDetails['payoutDetail']) {
          this.newAccumulatedArrears = data['extra']['newAccumulatedArrears']
          this.transferToBank = data['extra']['transferToBank']
          this.selectedWeekTotalIncome = data['extra']['selectedWeekTotalIncome']
          this.selectedWeekTotalCost = data['extra']['selectedWeekTotalCost']
          this.selectedWeekBalance = data['extra']['selectedWeekBalance']
        }

        this.driverPayoutSummaryText = data['extra']['summaryText']
      })

    },
    removeDriverDetails() {
      this.driver = '';
    },

    deleteGeneratedPayout() {

      confirm('Delete payout for ' + this.selectedWeek, () => {
        this.$store.dispatch('showLoader')

        const payload = {
          'week': this.selectedWeek
        }
        payoutService.deleteGeneratePayout(payload).then((data) => {
          this.$store.dispatch('hideLoader')

          if (!data.status) {
            this.$store.dispatch('error', {message: data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message: data.message, showSwal: true})
          this.getGeneratedPayout();

        })
      })

    },


  },
  watch: {
    driver: function (driver) {
      if (driver) {
        this.getArrearsForDriver();
        this.getDriverPayoutDetails();
      }
    }
  },
  created() {
    this.$store.dispatch('setPageTitle', 'Make payout');
    this.selectedWeek = this.getPreviousWeekInHtmlFormat();
   this.getGeneratedPayout()
  },

  mounted() {
    OneSignal.on('notificationDisplay', (event) => {
      let {data} = event;
      if (data.title === 'confirm_payout') {
        this.$store.dispatch('setProcessingInfo', event.heading);
      }
    });
  }
}
</script>

<style scoped>
thead {
  position: sticky !important;
  top: 0 !important;
  z-index: 10 !important;
}
</style>